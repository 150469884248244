import styled from 'styled-components';
import {media} from '@brightlive/shared/styles/breakpoints';

const Ag: Record<string, React.ElementType> = {};

Ag.DisplayXL = styled.h1`
  font-family: ${props => props.theme.fonts.primary};
  color: ${props => props.theme.contentColor.contentDefault};
  font-weight: ${props => props.theme.fontWeight.bold};
  letter-spacing: ${props => props.theme.letterSpacing['2XS']};
  font-size: ${props => props.theme.fontSize[800]};
  line-height: ${props => props.theme.lineHeight[800]};

  ${media.desktop} {
    font-size: ${props => props.theme.fontSize[1100]};
    line-height: ${props => props.theme.lineHeight[1100]};
  }
`;

Ag.DisplayLG = styled.h2`
  font-family: ${props => props.theme.fonts.primary};
  color: ${props => props.theme.contentColor.contentDefault};
  font-weight: ${props => props.theme.fontWeight.bold};
  letter-spacing: ${props => props.theme.letterSpacing.XS};
  font-size: ${props => props.theme.fontSize[700]};
  line-height: ${props => props.theme.lineHeight[700]};

  ${media.desktop} {
    letter-spacing: ${props => props.theme.letterSpacing['2XS']};
    font-size: ${props => props.theme.fontSize[1000]};
    line-height: ${props => props.theme.lineHeight[1000]};
  }
`;

Ag.DisplayMD = styled.h3`
  font-family: ${props => props.theme.fonts.primary};
  color: ${props => props.theme.contentColor.contentDefault};
  font-weight: ${props => props.theme.fontWeight.bold};
  letter-spacing: ${props => props.theme.letterSpacing.XS};
  font-size: ${props => props.theme.fontSize[700]};
  line-height: ${props => props.theme.lineHeight[700]};

  ${media.desktop} {
    ${props => getDisplayMDDesktop(props.theme)}
  }
`;

export const getDisplayMDDesktop = theme => {
  return `
    font-size: ${theme.fontSize[900]};
    line-height: ${theme.lineHeight[900]};
    letter-spacing: ${theme.letterSpacing['2XS']};
  `;
};

export const getHeadingLGDesktop = theme => {
  return `
    font-size: ${theme.fontSize[600]};
    line-height: ${theme.lineHeight[600]};
    letter-spacing: ${theme.letterSpacing.SM};
  `;
};

Ag.HeadingLG = styled.h2`
  font-family: ${props => props.theme.fonts.primary};
  color: ${props => props.theme.contentColor.contentDefault};
  font-weight: ${props => props.theme.fontWeight.bold};
  letter-spacing: ${props => props.theme.letterSpacing.SM};
  font-size: ${props => props.theme.fontSize[500]};
  line-height: ${props => props.theme.lineHeight[700]};

  ${media.desktop} {
    ${props => getHeadingLGDesktop(props.theme)}
  }
`;

Ag.HeadingMD = styled.h3`
  font-family: ${props => props.theme.fonts.primary};
  color: ${props => props.theme.contentColor.contentDefault};
  font-weight: ${props => props.theme.fontWeight.bold};
  letter-spacing: ${props => props.theme.letterSpacing.SM};
  font-size: ${props => props.theme.fontSize[400]};
  line-height: ${props => props.theme.lineHeight[400]};
`;

export const getHeadingSM = theme => {
  return `
    font-family: ${theme.fonts.primary};
    color: ${theme.contentColor.contentDefault};
    font-weight: ${theme.fontWeight.medium};
    letter-spacing: ${theme.letterSpacing.MD};
    font-size: ${theme.fontSize[300]};
    line-height: ${theme.lineHeight[200]};
  `;
};

Ag.HeadingSM = styled.h4`
  ${props => getHeadingSM(props.theme)}
`;

Ag.HeadingXS = styled.h5`
  font-family: ${props => props.theme.fonts.primary};
  color: ${props => props.theme.contentColor.contentDefault};
  font-weight: ${props => props.theme.fontWeight.bold};
  letter-spacing: ${props => props.theme.letterSpacing.MD};
  font-size: ${props => props.theme.fontSize[100]};
  line-height: ${props => props.theme.lineHeight[100]};
`;

export const getParagraphLG = theme => {
  return `
    font-family: ${theme.fonts.primary};
    color: ${theme.contentColor.contentDefault};
    font-weight: ${theme.fontWeight.regular};
    letter-spacing: ${theme.letterSpacing.MD};
    font-size: ${theme.fontSize[200]};
    line-height: ${theme.lineHeight[200]};
  `;
};

Ag.ParagraphLG = styled.p`
  ${props => getParagraphLG(props.theme)}
`;

export const getParagraphMD = theme => {
  return `
    font-family: ${theme.fonts.primary};
    color: ${theme.contentColor.contentDefault};
    font-weight: ${theme.fontWeight.regular};
    letter-spacing: ${theme.letterSpacing.MD};
    font-size: ${theme.fontSize[100]};
    line-height: ${theme.lineHeight[100]};
  `;
};

Ag.ParagraphMD = styled.p`
  ${props => getParagraphMD(props.theme)}
`;

Ag.ParagraphSM = styled.p`
  font-family: ${props => props.theme.fonts.primary};
  color: ${props => props.theme.contentColor.contentDefault};
  font-weight: ${props => props.theme.fontWeight.regular};
  letter-spacing: ${props => props.theme.letterSpacing.MD};
  font-size: ${props => props.theme.fontSize[50]};
  line-height: ${props => props.theme.lineHeight[50]};
`;

export const getParagraphXS = theme => {
  return `
    font-family: ${theme.fonts.primary};
    color: ${theme.contentColor.contentDefault};
    font-weight: ${theme.fontWeight.regular};
    letter-spacing: ${theme.letterSpacing.MD};
    font-size: ${theme.fontSize[25]};
    line-height: ${theme.lineHeight[25]};
  `;
};

Ag.ParagraphXS = styled.p`
  ${props => getParagraphXS(props.theme)}
`;

Ag.LinkLG = styled.a`
  font-family: ${props => props.theme.fonts.primary};
  color: ${props => props.theme.contentColor.contentDefault};
  font-weight: ${props => props.theme.fontWeight.bold};
  letter-spacing: ${props => props.theme.letterSpacing.MD};
  font-size: ${props => props.theme.fontSize[200]};
  line-height: ${props => props.theme.lineHeight[200]};
  text-decoration: underline;
`;

Ag.Link = styled.a`
  font-family: ${props => props.theme.fonts.primary};
  color: ${props => props.theme.contentColor.contentDefault};
  font-weight: ${props => props.theme.fontWeight.bold};
  letter-spacing: ${props => props.theme.letterSpacing.MD};
  font-size: ${props => props.theme.fontSize[100]};
  line-height: ${props => props.theme.lineHeight[100]};
  text-decoration: underline;
`;

Ag.LinkSM = styled.a`
  font-family: ${props => props.theme.fonts.primary};
  color: ${props => props.theme.contentColor.contentDefault};
  font-weight: ${props => props.theme.fontWeight.bold};
  letter-spacing: ${props => props.theme.letterSpacing.MD};
  font-size: ${props => props.theme.fontSize[50]};
  line-height: ${props => props.theme.lineHeight[50]};
  text-decoration: underline;
`;

Ag.LinkXS = styled.a`
  font-family: ${props => props.theme.fonts.primary};
  color: ${props => props.theme.contentColor.contentDefault};
  font-weight: ${props => props.theme.fontWeight.bold};
  letter-spacing: ${props => props.theme.letterSpacing.MD};
  font-size: ${props => props.theme.fontSize[25]};
  line-height: ${props => props.theme.lineHeight[25]};
  text-decoration: underline;
`;

Ag.ButtonLabel = styled.p`
  font-family: ${props => props.theme.fonts.primary};
  color: ${props => props.theme.contentColor.contentDefault};
  font-weight: ${props => props.theme.fontWeight.medium};
  letter-spacing: ${props => props.theme.letterSpacing.MD};
  font-size: ${props => props.theme.fontSize[100]};
  line-height: ${props => props.theme.lineHeight[100]};
`;

export const getButtonLabelSM = theme => {
  return `
    font-family: ${theme.fonts.primary};
    color: ${theme.contentColor.contentDefault};
    font-weight: ${theme.fontWeight.medium};
    letter-spacing: ${theme.letterSpacing.MD};
    font-size: ${theme.fontSize[50]};
    line-height: ${theme.lineHeight[50]};
  `;
};

Ag.ButtonLabelSM = styled.p`
  ${props => getButtonLabelSM(props.theme)}
`;

export const getEyebrow = theme => {
  return `
    font-family: ${theme.fonts.primary};
    color: ${theme.contentColor.contentDefault};
    font-weight: ${theme.fontWeight.medium};
    letter-spacing: ${theme.letterSpacing.LG};
    font-size: ${theme.fontSize[50]};
    line-height: ${theme.fontSize[50]};
    text-transform: uppercase;
  `;
};

Ag.Eyebrow = styled.p`
  ${props => getEyebrow(props.theme)}
`;

Ag.BoxHeading = styled(Ag.HeadingMD)`
  padding: ${props => props.theme.spacing.MD};
  background: ${props => props.theme.backgroundColor.backgroundPrimary};
  border: ${props => props.theme.borderWidth[1]} solid
    ${props => props.theme.borderColor.borderPrimary};
  box-shadow: ${props => props.theme.spacing.MD}
    ${props => props.theme.spacing.MD} 0px
    ${props => props.theme.color.indigo500};
  display: inline-block;
  position: relative;
  top: -1px;
`;

export default Ag;
