import styled, {DefaultTheme} from 'styled-components';
import {focusStyles, hover, active} from '../../styles/pseudoClasses';
// Helpers
import {ButtonTypes} from '../../helpers/interfaces';
import {Sizes} from './index';
import {getButtonLabelSM, getButtonLabelMD} from '../../styles/typography-v3';

const S: Record<string, React.ElementType> = {};

const fontSize = {
  small: '14px',
  medium: '16px',
  large: '16px',
};

const lineHeight = {
  small: '20px',
  medium: '24px',
  large: '24px',
};

const getFont = (size, theme) => {
  if (size === 'small') {
    return getButtonLabelSM(theme);
  } else {
    return getButtonLabelMD(theme);
  }
};

const getMinWidth = (size: Sizes, theme: DefaultTheme) => {
  // min-width = button height / 2
  // height = line height + top padding + bottom padding
  return `${
    (parseInt(lineHeight[size], 10) +
      parseInt(getPadding(size, theme).split(' ')[0], 10)) *
    2
  }px`;
};

const getPadding = (size: Sizes, theme: DefaultTheme) => {
  switch (size) {
    case 'small':
      return `6px ${theme.spacing.MD}`;
    case 'medium':
      return `${theme.spacing.SM} ${theme.spacing.LG}`;
    case 'large':
      return `${theme.spacing.MD} ${theme.spacing.XL}`;
  }
};

export const colors = {
  background: {
    disabled: {
      primary: 'backgroundDisabled',
      'primary inverse': 'backgroundInverseDisabled',
      secondary: 'backgroundTransparent',
      'secondary inverse': 'backgroundTransparent',
      tertiary: 'backgroundTransparent',
      'tertiary inverse': 'backgroundTransparent',
      lightning: 'actionBrand',
      'lightning secondary': 'backgroundBlackTransparent',
      negative: 'backgroundDisabled',
    },
    default: {
      primary: 'backgroundInversePrimary',
      'primary inverse': 'backgroundPrimary',
      secondary: 'backgroundTransparent',
      'secondary inverse': 'backgroundTransparent',
      tertiary: 'backgroundTransparent',
      'tertiary inverse': 'backgroundTransparent',
      lightning: 'actionBrand',
      'lightning secondary': 'backgroundBlackTransparent',
      negative: 'backgroundNegative',
    },
  },
  border: {
    disabled: {
      primary: '',
      'primary inverse': '',
      secondary: 'borderSubdued',
      'secondary inverse': 'borderInverseSubdued',
      tertiary: '',
      'tertiary inverse': '',
      lightning: '',
      'lightning secondary': '',
      negative: 'borderInverseSubdued',
    },
    default: {
      primary: '',
      'primary inverse': '',
      secondary: 'borderPrimary',
      'secondary inverse': 'borderInversePrimary',
      tertiary: '',
      'tertiary inverse': '',
      lightning: '',
      'lightning secondary': 'actionBrand',
      negative: 'borderInversePrimary',
    },
  },
  overlay: {
    hover: {
      primary: 'actionInverseHover',
      'primary inverse': 'actionHover',
      secondary: 'actionHover',
      'secondary inverse': 'actionInverseHover',
      tertiary: 'actionHover',
      'tertiary inverse': 'actionInverseHover',
      lightning: 'actionHover',
      'lightning secondary': 'actionInverseHover',
      negative: 'actionHover',
    },
    pressed: {
      primary: 'actionInversePressed',
      'primary inverse': 'actionPressed',
      secondary: 'actionPressed',
      'secondary inverse': 'actionInversePressed',
      tertiary: 'actionPressed',
      'tertiary inverse': 'actionInversePressed',
      lightning: 'actionPressed',
      'lightning secondary': 'actionInversePressed',
      negative: 'actionPressed',
    },
  },
  text: {
    disabled: {
      primary: 'contentDisabled',
      'primary inverse': 'contentInverseDisabled',
      secondary: 'contentDisabled',
      'secondary inverse': 'contentInverseDisabled',
      tertiary: 'contentDisabled',
      'tertiary inverse': 'contentInverseDisabled',
      lightning: 'contentDefault',
      'lightning secondary': 'contentBrand',
      negative: 'contentInverse',
    },
    default: {
      primary: 'contentInverse',
      'primary inverse': 'contentDefault',
      secondary: 'contentDefault',
      'secondary inverse': 'contentInverse',
      tertiary: 'contentDefault',
      'tertiary inverse': 'contentInverse',
      lightning: 'contentDefault',
      'lightning secondary': 'contentBrand',
      negative: 'contentInverse',
    },
  },
  focus: {
    primary: 'focus',
    'primary inverse': 'focusInverse',
    secondary: 'focus',
    'secondary inverse': 'focusInverse',
    tertiary: 'focus',
    'tertiary inverse': 'focusInverse',
    lightning: 'focusInverse',
    'lightning secondary': 'focusInverse',
    negative: 'focus',
  },
};

const getColor = (
  theme: DefaultTheme,
  disabled: boolean,
  type: ButtonTypes,
  position: string
) => {
  if (disabled) {
    if (position === 'text') {
      return theme.contentColor[colors[position].disabled[type]];
    }
    if (position === 'border') {
      return theme.borderColor[colors[position].disabled[type]];
    }
    if (position === 'background') {
      return theme.backgroundColor[colors[position].disabled[type]];
    }
  } else {
    if (position === 'text') {
      return theme.contentColor[colors[position].default[type]];
    }
    if (position === 'border') {
      return theme.borderColor[colors[position].default[type]];
    }
    if (position === 'background') {
      return theme.backgroundColor[colors[position].default[type]];
    }
  }
};

const getBorder = (
  theme: DefaultTheme,
  disabled: boolean,
  type: ButtonTypes
) => {
  const borderColor = getColor(theme, disabled, type, 'border');
  if (borderColor) {
    return `${theme.borderWidth[1]} solid ${borderColor}`;
  }
  return '';
};

// Needed in case the button is inside of a column flex and width is auto
// Otherwise S.ButtonWrapper by itself will take up the whole width of it's parent and make the focus outline extend that far too
S.ButtonWrapperOuter = styled.div(
  (props: {$width: string; disabled: boolean}) => `
  width: ${props.$width || 'auto'};
  cursor: ${props.disabled ? 'auto' : 'pointer'};

  &:focus {
    outline: none;
  }
`
);

S.ButtonWrapper = styled.div(
  (props: {
    $width: string;
    $type: ButtonTypes;
    disabled: boolean;
    theme: DefaultTheme;
  }) => `
  display: inline-flex;
  position: relative;
  width: ${props.$width || 'auto'};
  ${hover(
    props.theme.backgroundColor[colors.overlay.hover[props.$type]],
    props.disabled
  )}
  ${active(
    props.theme.backgroundColor[colors.overlay.pressed[props.$type]],
    props.disabled
  )}
`
);

S.FocusBorder = styled.div(
  (props: {theme: DefaultTheme; $type: ButtonTypes; disabled: boolean}) => `
    ${focusStyles(
      props.theme.focusColor[colors.focus[props.$type]],
      props.disabled
    )}
`
);

S.Button = styled.div(
  (props: {
    theme: DefaultTheme;
    $width: string;
    $type: ButtonTypes;
    disabled: boolean;
    $isLoading: boolean;
    $size: Sizes;
    $justifyContent: string;
  }) => `
  width: ${props.$width || 'auto'};
  min-width: ${getMinWidth(props.$size, props.theme)};
  padding: ${getPadding(props.$size, props.theme)};
  border-radius: 100px;
  display: inline-flex;
  justify-content: ${props.$justifyContent || 'center'};
  align-items: center;
  box-sizing: border-box;
  background-color: ${getColor(
    props.theme,
    props.disabled,
    props.$type,
    'background'
  )};
  border: ${getBorder(props.theme, props.disabled, props.$type)}
  };
  position: relative;

  &:hover {
    border-width: ${props.theme.borderWidth[1]};
  }
`
);

S.TextWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

S.Icon = styled.div`
  margin-right: ${props => props.theme.spacing.XS};
  display: flex;
`;

S.Text = styled.p(
  (props: {
    $type: ButtonTypes;
    disabled: boolean;
    $isLoading: boolean;
    theme: DefaultTheme;
    $size: Sizes;
    $v3: boolean;
  }) => `
  ${
    props.$v3
      ? getFont(props.$size, props.theme)
      : `
        font-family: ${props.theme.fonts.primary};
        font-size: ${fontSize[props.$size]};
        font-weight: 500;
        line-height: ${lineHeight[props.$size]};
      `
  }

  color: ${getColor(props.theme, props.disabled, props.$type, 'text')};
  opacity: ${props.$isLoading ? 0 : 1};
  white-space: nowrap;
  text-decoration: ${
    props.$type === 'tertiary' || props.$type === 'tertiary inverse'
      ? 'underline'
      : 'none'
  };
`
);

S.LoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default S;
