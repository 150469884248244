import styled from 'styled-components';
import {media} from '@brightlive/shared/styles/breakpoints';

const Ag: Record<string, React.ElementType> = {};

Ag.DisplayXL = styled.h1`
  font-family: ${props => props.theme.fonts.owners};
  color: ${props => props.theme.contentColor.contentDefault};
  font-weight: ${props => props.theme.fontWeight.medium};
  font-size: 40px;
  line-height: 44px;

  ${media.desktop} {
    font-size: 80px;
    line-height: 80px;
  }
`;

Ag.DisplayLG = styled.h2`
  font-family: ${props => props.theme.fonts.owners};
  color: ${props => props.theme.contentColor.contentDefault};
  font-weight: ${props => props.theme.fontWeight.medium};
  font-size: 40px;
  line-height: 44px;

  ${media.desktop} {
    font-size: 64px;
    line-height: 64px;
  }
`;

Ag.DisplayMD = styled.h3`
  font-family: ${props => props.theme.fonts.owners};
  color: ${props => props.theme.contentColor.contentDefault};
  font-weight: ${props => props.theme.fontWeight.medium};
  font-size: 32px;
  line-height: 36px;

  ${media.desktop} {
    font-size: 48px;
    line-height: 52px;
  }
`;

export const getHeadingLGDesktop = theme => {
  return `
    font-family: ${theme.fonts.owners};
    color: ${theme.contentColor.contentDefault};
    font-weight: ${theme.fontWeight.medium};
    font-size: 32px;
    line-height: 36px;
  `;
};

Ag.HeadingLG = styled.h2`
  font-family: ${props => props.theme.fonts.owners};
  color: ${props => props.theme.contentColor.contentDefault};
  font-weight: ${props => props.theme.fontWeight.medium};
  font-size: 28px;
  line-height: 32px;

  ${media.desktop} {
    ${props => getHeadingLGDesktop(props.theme)}
  }
`;

export const getHeadingMD = theme => {
  return `
    font-family: ${theme.fonts.owners};
    color: ${theme.contentColor.contentDefault};
    font-weight: ${theme.fontWeight.medium};
    font-size: 24px;
    line-height: 28px;
  `;
};

Ag.HeadingMD = styled.h3`
  ${props => getHeadingMD(props.theme)}
`;

export const getHeadingSM = theme => {
  return `
  font-family: ${theme.fonts.ownersText};
  color: ${theme.contentColor.contentDefault};
  font-weight: ${theme.fontWeight.bold};
  font-size: 20px;
  line-height: 24px;
  `;
};

Ag.HeadingSM = styled.h4`
  ${props => getHeadingSM(props.theme)}
`;

Ag.HeadingXS = styled.h5`
  font-family: ${props => props.theme.fonts.ownersText};
  color: ${props => props.theme.contentColor.contentDefault};
  font-weight: ${props => props.theme.fontWeight.bold};
  font-size: 16px;
  line-height: 20px;
`;

Ag.Heading2XS = styled.h5`
  font-family: ${props => props.theme.fonts.ownersText};
  color: ${props => props.theme.contentColor.contentDefault};
  font-weight: ${props => props.theme.fontWeight.bold};
  font-size: 14px;
  line-height: 18px;
`;

Ag.ParagraphLG = styled.p`
  font-family: ${props => props.theme.fonts.ownersText};
  color: ${props => props.theme.contentColor.contentDefault};
  font-weight: ${props => props.theme.fontWeight.regular};
  font-size: 18px;
  line-height: 24px;

  ${media.desktop} {
    font-size: 20px;
    line-height: 28px;
  }
`;

Ag.ParagraphMD = styled.p`
  font-family: ${props => props.theme.fonts.ownersText};
  color: ${props => props.theme.contentColor.contentDefault};
  font-weight: ${props => props.theme.fontWeight.regular};
  font-size: 16px;
  line-height: 24px;
`;

Ag.ParagraphSM = styled.p`
  font-family: ${props => props.theme.fonts.ownersText};
  color: ${props => props.theme.contentColor.contentDefault};
  font-weight: ${props => props.theme.fontWeight.regular};
  font-size: 14px;
  line-height: 20px;
`;

Ag.ParagraphXS = styled.p`
  font-family: ${props => props.theme.fonts.ownersText};
  color: ${props => props.theme.contentColor.contentDefault};
  font-weight: ${props => props.theme.fontWeight.regular};
  font-size: 12px;
  line-height: 16px;
`;

Ag.Link = styled.a`
  cursor: pointer;
  color: #4966d7;
`;

Ag.LinkBold = styled.a`
  font-weight: ${props => props.theme.fontWeight.medium};
  text-decoration: underline;
  cursor: pointer;
  color: #4966d7;
`;

Ag.Bold = styled.span`
  font-weight: ${props => props.theme.fontWeight.medium};
`;

export const getButtonLabelMD = theme => {
  return `
    font-family: ${theme.fonts.ownersText};
    font-weight: ${theme.fontWeight.medium};
    font-size: 16px;
    line-height: 24px;
  `;
};

Ag.ButtonLabelMD = styled.p`
  ${props => getButtonLabelMD(props.theme)}
`;

export const getButtonLabelSM = theme => {
  return `
    font-family: ${theme.fonts.ownersText};
    font-weight: ${theme.fontWeight.medium};
    font-size: 14px;
    line-height: 20px;
  `;
};

Ag.ButtonLabelSM = styled.p`
  ${props => getButtonLabelSM(props.theme)}
`;

Ag.Eyebrow = styled.p`
  font-family: ${props => props.theme.fonts.ownersNarrow};
  color: ${props => props.theme.contentColor.contentDefault};
  font-weight: ${props => props.theme.fontWeight.bold};
  letter-spacing: 0.08em;
  font-size: 14px;
  line-height: 14px;
  text-transform: uppercase;
`;

Ag.EyebrowSM = styled.p`
  font-family: ${props => props.theme.fonts.ownersText};
  color: ${props => props.theme.contentColor.contentDefault};
  font-weight: ${props => props.theme.fontWeight.medium};
  letter-spacing: 0.08em;
  font-size: 12px;
  line-height: 12px;
  text-transform: uppercase;
`;

export default Ag;
